/// Color - START
@bg-white: #ffffff;
@bg-black: #000000;
@color-white: #ffffff;
@color-black: #000000;
@main-app-color: #004b57;
@main-border-color: #f0f0f0;
@border-color-2: #e1e1e1;
@border-color-3: #d0d0d0;
@border-color-4: #d9d9d9;
@subtitle-color: #8e8e8e;
@color-sucess: #49aa19;
@color-error: #f00f00;
@color-link: #0e65cc;

/// Color - END

/// Size - START
@px-2: 0.1302vw;
@pxh-2: 0.2828vh;
@px-5: 0.3255vw;
@pxh-5: 0.707vh;
@rem-2: 0.125rem;
@rem-5: 0.3125rem;
@px-10: @px-5 * 2;
@px-15: @px-5 * 3;
@px-20: @px-5 * 4;
@px-25: @px-5 * 5;
@px-30: @px-5 * 6;
@px-50: @px-5 * 10;
@px-100: @px-5 * 20;
@pxh-10: @pxh-5 * 2;
@pxh-15: @pxh-5 * 3;
@pxh-20: @pxh-5 * 4;
@pxh-25: @pxh-5 * 5;
@pxh-30: @pxh-5 * 6;
@pxh-50: @pxh-5 * 10;
@pxh-100: @pxh-5 * 20;
/// Size - END

/// Font-size - START
@font-size-2: 0.125rem;
@font-size-8: 0.5rem;
@font-size-10: 0.625rem;
@font-size-11: 0.6875rem;
@font-size-12: 0.75rem;
@font-size-13: 0.8125rem;
@font-size-14: 0.875rem;
@font-size-15: 0.9375rem;
@font-size-16: 1rem;
@font-size-18: 1.125rem;
@font-size-20: 1.25rem;
@font-size-22: 1.375rem;
@font-size-23: 1.4375rem;
@font-size-24: 1.5rem;
@font-size-25: 1.5625rem;
@font-size-26: 1.625rem;
@font-size-28: 1.75rem;
@font-size-30: 1.875rem;
@font-size-32: 2rem;
@font-size-36: 2.25rem;
/// Font-size - END

/// reusable function == START
.font-bold {
    font-weight: bold;
}
.font-size-2px(@times) {
    font-size: @font-size-2 * @times;
}
.font-size-2px-bold(@times) {
    .font-size-2px(@times);
    font-weight: bold;
}
.padding-20px {
    padding-top: @pxh-20;
}
.margin-top-10px {
    margin-top: @pxh-10;
}
.padding-2px(@times) {
    padding: (@pxh-2 * @times) (@px-2 * @times);
}
.padding-2px(@timesHeight, @timeWidth) {
    padding: (@pxh-2 * @timesHeight) (@px-2 * @timeWidth);
}
.padding-2px-important(@times) {
    padding: (@pxh-2 * @times) (@px-2 * @times) !important;
}
.padding-2px-important(@timesHeight, @timeWidth) {
    padding: (@pxh-2 * @timesHeight) (@px-2 * @timeWidth) !important;
}
.margin-2px(@times) {
    margin: (@pxh-2 * @times) (@px-2 * @times);
}
.margin-2px(@timesHeight, @timeWidth) {
    margin: (@pxh-2 * @timesHeight) (@px-2 * @timeWidth);
}
.margin-2px-important(@times) {
    margin: (@pxh-2 * @times) (@px-2 * @times) !important;
}
.margin-2px-important(@timesHeight, @timeWidth) {
    margin: (@pxh-2 * @timesHeight) (@px-2 * @timeWidth) !important;
}
/// reusable function == END

/// reusable class == START
.vertical-top {
    vertical-align: top !important;
}
.text-line-throught {
    text-decoration: line-through;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.hide {
    display: none !important;
}
.main-bg {
    background: @primary-color !important;
}
.main-bg-white {
    background-color: @bg-white !important;
}
.bg-warning {
    background-color: #fff7e6 !important;
}
.main-border {
    border: 1px solid @main-border-color;
}
.main-border-bottom {
    border-bottom: 1px solid @main-border-color;
}
.main-border-2 {
    border: 1px solid @border-color-2;
}
.main-border-2-bottom {
    border-bottom: 1px solid @border-color-2;
}
.main-border-3 {
    border: 1px solid @border-color-3;
}
.main-border-4 {
    border: 1px solid @border-color-4;
}
.main-border-4-bottom {
    border-bottom: 1px solid @border-color-4;
}
.main-border-bg {
    background-color: @main-border-color;
}
.flex-column {
    display: flex !important;
    flex-direction: column !important;
}
.flex-column-center {
    .flex-column;
    align-items: center !important;
}
.flex-row {
    display: flex !important;
    flex-direction: row !important;
}
.flex-row-between {
    .flex-row;
    justify-content: space-between !important;
}
.highest-z-index {
    z-index: 10;
    position: relative;
}
.bold-title {
    .font-bold;
}
.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.nice-scrollbar {
    &::-webkit-scrollbar {
        width: @px-2 * 6;
        height: @pxh-2 * 6;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: @px-100;
        background-color: @main-app-color;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-track {
        background: #cecece;
        border-radius: @px-30;
    }
}
.shadow {
    &-1 {
        box-shadow: 0px 1px 4px rgb(0 0 0 / 40%) !important;
    }
    &-2 {
        box-shadow: 0px 1px 3px rgb(0 0 0 / 30%) !important;
    }
    &-light {
        box-shadow: 0px 1px 2px rgb(0 0 0 / 30%) !important;
    }
}
.text-color {
    &-success {
        color: @color-sucess;
    }
    &-error {
        color: @color-error;
    }
}
.bg {
    &-red {
        &-800 {
            background-color: #991b1b;
        }
    }

    &-yellow {
        &-700 {
            background-color: #a16207;
        }
    }
}
/// reusable class == END

.notification-channel-creat-container {
    .line-description-panel {
        & > div {
            &:nth-child(1) {
                font-weight: bold;
                color: #5dc020;
            }
            &:nth-child(3) {
                .flex-column;
                span {
                    font-weight: bold;
                }
                ol {
                    padding-left: @px-15;
                }
            }
        }
    }

    .whatsapp-description-panel {
        & > div {
            &:nth-child(1) {
                font-weight: bold;
                color: #5dc020;
            }
            &:nth-child(2) {
                font-weight: bold;
            }
            &:nth-child(3) {
                .font-size-2px-bold(9);
                .padding-2px(5, 1);
            }
            &:nth-child(4) {
                .flex-column;
                span {
                    font-weight: bold;
                }
                ol {
                    padding-left: @px-15;
                }
            }
        }
    }

    .lark-description-panel {
        & > div {
            &:nth-child(1) {
                font-weight: bold;
                color: #5dc020;
            }
            &:nth-child(2) {
                .padding-2px(5, 1);
            }
            &:nth-child(3) {
                span {
                    font-weight: bold;
                }
                ol {
                    padding-left: @px-15;
                }
            }
        }
    }
}
